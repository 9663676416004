/* Item View, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="item-view" class="entity-view">
      <v-card-title>View Item</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'item-edit', params: { id: entity.Id } }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <span>Edit</span>
        </v-btn>
        </v-col>
        <v-col class="text-right">
        <!-- <v-btn
          color="secondary"
          class="me-3"
          :to="{ name: 'item-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Item</span>
        </v-btn> -->
        <v-btn color="secondary" class="me-3" @click="Back">
          <v-icon size="18" class="me-1">{{ icons.mdiChevronLeft }}</v-icon>
          <span>Back</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-simple-table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Order Reference</td>
        <td>{{ entity.OrderId_Reference }}</td>
      </tr>

      <tr>
        <td>Length</td>
        <td>{{ entity.Length }}</td>
      </tr>

      <tr>
        <td>Width</td>
        <td>{{ entity.Width }}</td>
      </tr>

      <tr>
        <td>Height</td>
        <td>{{ entity.Height }}</td>
      </tr>

      <tr>
        <td>Damaged</td>
        <td><v-icon size="18" :color="booleanIcons(entity.Damaged).variant">{{ booleanIcons(entity.Damaged).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>RefusedDelivery</td>
        <td><v-icon size="18" :color="booleanIcons(entity.RefusedDelivery).variant">{{ booleanIcons(entity.RefusedDelivery).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Guesstimated</td>
        <td><v-icon size="18" :color="booleanIcons(entity.Guesstimated).variant">{{ booleanIcons(entity.Guesstimated).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Do Not Send</td>
        <td><v-icon size="18" :color="booleanIcons(entity.Guesstimated).variant">{{ booleanIcons(entity.Guesstimated).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Notes</td>
        <td>{{ entity.Notes }}</td>
      </tr>

      <tr>
        <td>User FullName</td>
        <td>{{ entity.BookedInBy_FullName }}</td>
      </tr>

		      <tr>
        <td>User Email</td>
        <td>{{ entity.BookedInBy_Email }}</td>
      </tr>

      <tr>
        <td>Created</td>
        <td>{{ Display.DateTime(entity.Created) }}</td>
      </tr>

      <tr>
        <td>Updated</td>
        <td>{{ Display.DateTime(entity.Updated) }}</td>
      </tr>

        </tbody>
      </v-simple-table>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'

import {
  mdiPencilOutline,
  mdiPlus,
  mdiViewList,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiClose,
  mdiChevronLeft
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'

export default {
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.Item);
    const loading = ref(false);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchItem', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'item-list'});
        })
    }

    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);


    const booleanIcons = status => {
      if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
      return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
    }

    const Back = () => {
      router.go(-1);
    };

    return {
      Back,
      loading,
      entity,
      Display,
      booleanIcons,
      icons: {
        mdiPencilOutline,
        mdiPlus,
        mdiViewList,
        mdiClose,
        mdiChevronLeft
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-view::v-deep .v-data-table th:last-child {
  width: 66%;
}
</style>

